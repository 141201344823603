import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Form, Formik} from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

// @ts-ignore
import {ButtonPrimary, StyledInput} from 'pyrexx-react-library'
import EmailConfirmation from './EmailConfirmation'
import FloorSelector from '../../components/FloorSelector'
import {gql, useMutation} from '@apollo/client'

import {Floor} from '../../components/FloorSelector/FloorSelector'

import {push_subscribe} from './pushNotificationHelpers'
import BulletinCard from "./BulletinCard"

const ADD_NOTIFICATION_SUBSCRIPTION = gql`
  mutation AddNotificationSubscription(
    $bulletinId: Int!
    $code: Int!
    $timeframe: String!
    $email: String!
    $phoneNumber: String
    $push: PushEndPoint
  ) {
    addNotificationSubscription(
      bulletinId: $bulletinId
      code: $code
      timeFrame: $timeframe
      email: $email
      smsNumber: $phoneNumber
      push: $push
    ) {
      success
      message
    }
  }
`

const initialFormValues = {
  email: '',
  timeframe: '',
  subscribeToPushNotifications: false,
  subscribeToSMSNotifications: false,
  phoneNumber: '',
  push: null,
}

type NotificationsSubscriptionProps = {
  appointmentData: []
  bulletinId: number,
  code: number,
  floors: Floor[],
  callOutCharge: string
}

const NotificationsSubscription = (props: NotificationsSubscriptionProps) => {
  const {appointmentData, bulletinId, code, floors = [], callOutCharge} = props

  const {t} = useTranslation()

  const [addNotificationSubscription, {data, loading, error}] = useMutation(
    ADD_NOTIFICATION_SUBSCRIPTION,
    {
      errorPolicy: 'all',
    },
  )

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('INVALID EMAIL'))
      .required(t('PLEASE PROVIDE AN EMAIL')),
    timeframe: Yup.string().required(),
    subscribeToPushNotifications: Yup.bool(),
    subscribeToSMSNotifications: Yup.bool(),
    phoneNumber: Yup.string().when('subscribeToSMSNotifications', {
      is: true,
      then: (schema) => schema.required('PLEASE PROVIDE A VALID PHONE NUMBER'),
      otherwise: (schema) => schema,
    }),
    // push: Yup.object().shape({
    //   endpoint: Yup.string().required(t('ADD A VALID PUSH TYPE')),
    //   publicKey: Yup.string().required(t('ADD A VALID PUSH TYPE')),
    //   token: Yup.string().required(t('ADD A VALID PUSH TYPE')),
    //   encoding: Yup.string().required(t('ADD A VALID PUSH TYPE')),
    // }) || null
  })

  // @ts-ignore
  const onFormSubmit = (values) => {
    const subscribeToPushNotifications = values?.subscribeToPushNotifications
    const phoneNumber = values?.subscribeToSMSNotifications
      ? values?.phoneNumber
      : ''

    if (subscribeToPushNotifications) {
      push_subscribe().then((body) => {
        addNotificationSubscription({
          variables: {
            bulletinId,
            code,
            timeframe: values.timeframe,
            email: values.email,
            phoneNumber,
            push: body,
          },
        })
      })
    } else {
      addNotificationSubscription({
        variables: {
          bulletinId,
          code,
          timeframe: values.timeframe,
          email: values.email,
          phoneNumber,
          push: null,
        },
      })
    }
  }

  const getFloorNameByTimeframe = (timeframe: string): string | undefined => {
    const matchedFloor = floors.find(floor => floor.value === timeframe);
    return matchedFloor ? matchedFloor.label : undefined;
  };

  if (data?.addNotificationSubscription?.success) {
    return <EmailConfirmation/>
  }

  return (
    <div>

      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={schema}
      >

        {({values, touched, errors, handleChange, setFieldValue}) => {

          const reset = () => {
            setFieldValue("timeframe", "");
          };
          const currentFloorGroup = getFloorNameByTimeframe(values.timeframe)
          console.log(currentFloorGroup)

          return (
            <Form>
              {!values.timeframe && (
                <FloorSelector
                  name='timeframe'
                  id='timeframe'
                  floors={floors}
                  selectedFloor={values.timeframe}
                  // @ts-ignore
                  handleSelectChange={handleChange}
                />
              )}

              {values.timeframe && (
                <>
                  <BulletinCard location={appointmentData} datetime={values.timeframe}
                                resetSelector={reset} currentFloorGroup={currentFloorGroup} />
                  <div>
                    <h3>{t('SUBSCRIBE TO NOTIFICATIONS AND REALTIME UPDATES')}</h3>
                    <small>
                      {t('WE ARE ASSIGNED TO PERFORM OUR SERVICE.\n' +
                        'IF WE DO NOT HAVE ACCESS TO YOUR PREMISES ON THIS DATE,\n' +
                        'WE WILL LEAVE YOU A NOTIFICATION CARD\n' +
                        'WITH FURTHER APPOINTMENT OPTIONS.')}
                    </small>
                    <small>
                      {callOutCharge}
                    </small>

                    <div>
                      <StyledInput
                        type='email'
                        name='email'
                        id='email'
                        onChange={handleChange}
                        value={values.email}
                        label={t('EMAIL')}
                        setFieldValue={setFieldValue}
                        feedbackMessage={touched.email && errors.email}
                        placeholder={t('ENTER EMAIL')}
                      />

                      <EmailTooltip>
                        {t(
                          'WE WILL SEND YOU ONE EMAIL REMINDER THE DAY BEFORE YOUR APPOINTMENT',
                        )}
                      </EmailTooltip>
                    </div>


                    <div>
                      <StyledInput
                        type='checkbox'
                        name='subscribeToPushNotifications'
                        id='subscribeToPushNotifications'
                        onChange={handleChange}
                        value={values.subscribeToPushNotifications}
                        label={t('SUBSCRIBE TO PUSH NOTIFICATIONS')}
                        setFieldValue={setFieldValue}
                        feedbackMessage={
                          touched.subscribeToPushNotifications
                            ? errors.subscribeToPushNotifications
                            : ''
                        }
                      />

                      <Tooltip>
                        {t(
                          'WE WILL SEND YOU A PUSH NOTIFICATION ON THE MORNING OF YOUR APPOINTMENT',
                        )}
                      </Tooltip>
                    </div>

                    <div>
                      <CustomInputRow>
                        <StyledInput
                          type='checkbox'
                          name='subscribeToSMSNotifications'
                          id='subscribeToSMSNotifications'
                          onChange={handleChange}
                          value={values.subscribeToSMSNotifications}
                          setFieldValue={setFieldValue}
                          feedbackMessage={
                            touched.subscribeToSMSNotifications
                              ? errors.subscribeToSMSNotifications
                              : ''
                          }
                        />

                        <StyledInput
                          name='phoneNumber'
                          id='phoneNumber'
                          onChange={handleChange}
                          value={values.phoneNumber}
                          label={t('PHONE NUMBER')}
                          setFieldValue={setFieldValue}
                          feedbackMessage={
                            touched.phoneNumber ? errors.phoneNumber : ''
                          }
                          disabled={!values?.subscribeToSMSNotifications}
                          placeholder={t('ENTER PHONE NUMBER')}
                        />
                      </CustomInputRow>

                      <Tooltip>
                        {t(
                          'WE WILL SEND YOU ONE SMS AT 9AM WITH A LIVE TRACKING LINK ON THE MORNING OF YOUR APPOINTMENT',
                        )}
                      </Tooltip>
                    </div>
                    {values.subscribeToPushNotifications ||
                    values.subscribeToSMSNotifications ? (

                        <CenterRow>
                          <ButtonPrimary type='submit' isLoading={loading}>
                            {t('UPDATE SUBSCRIPTION')}
                          </ButtonPrimary>

                        </CenterRow>

                    ) : null}

                    {error ? (
                      <CenterRow>
                        <ErrorMessage>
                          {t('ERROR. PLEASE TRY AGAIN')}
                        </ErrorMessage>
                      </CenterRow>
                    ) : null}
                  </div>
                </>

              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const CustomInputRow = styled.div`
  display: flex;
  align-items: center;

  :nth-child(2) {
    flex-grow: 1;
  }
`

const CenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    margin-top: 1rem;
  }
`

const Tooltip = styled.p`
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  margin-left: 2.4rem;
  margin-bottom: 1rem;
`

const EmailTooltip = styled(Tooltip)`
  margin-left: unset;
`

const ErrorMessage = styled.p`
  color: #e8221b;
`

export default NotificationsSubscription
